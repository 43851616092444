
































import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
} from 'bootstrap-vue'

import {Component, Prop, Vue} from "vue-property-decorator";

import {VForm} from "@/global";

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
  }
})
export default class DivisionBreakdown extends Vue {
  $refs!: {
    form: VForm,
    createForm: VForm
  }

  @Prop({required: true})
  readonly divisions!: MaintenanceAPI.VincereInstance.DivisionItem[];
}
