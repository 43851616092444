









































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BAvatar, BButton, BCard, BCol, BFormGroup, BFormInput, BRow} from "bootstrap-vue";
import {avatarText} from '@/helpers/filters/avatarText';
import {isEqual} from "lodash";

@Component({
  components: {BCard, BButton, BRow, BCol, BAvatar, BFormGroup, BFormInput}
})
export default class VincereInstanceInfoCard extends Vue {
  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  hasBeenEdited: boolean;

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  item?: MaintenanceAPI.VincereInstance.Item;

  @Prop({
    default: () => {
      return {}
    }, required: true
  })
  isEditing: boolean;

  get avatarText() {
    return avatarText(this.item?.domain)
  }

  get id() {
    return this.$route.params.identifier;
  }

  async cancel() {
    if(this.id == "create") {
      return this.$router.push({name: "maintenance-vincereinstances"})
    }

    this.$emit('toggleEditing');
    this.$emit('refetch')
  }
}
