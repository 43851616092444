












































































































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {VForm} from "@/global";
import InfoCard from "./components/info-card.vue";
import {isEqual} from "lodash";
import {
  BButton,
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BModal,
  BSpinner,
  BTab,
  BTabs,
  VBModal
} from "bootstrap-vue";
import DivisionBreakdown from "@/apps/maintenance-api/pages/Vincere/single/components/division-breakdown.vue";

@Component({
  components: {
    DivisionBreakdown,
    InfoCard,
    BCard,
    BButton,
    BTabs,
    BTab,
    BModal,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BForm,
    BSpinner
  },
  directives: {
    'b-modal': VBModal,
  }
})
export default class Platform extends Vue {
  $refs!: {
    form: VForm,
    createForm: VForm
  }

  item: MaintenanceAPI.VincereInstance.Item = null;

  @Watch("id", {immediate: true})
  async watchId() {
    if (this.$route.params.edit != undefined)
      this.editing = true;

    await this.getItem();
  }

  get id() {
    return this.$route.params.identifier;
  }

  toggleEditing() {
    this.editing = !this.editing;
  }

  editing = false;

  async getItem() {
    const item = await this.$api.maintenance.vincere.Get(this.id);
    this.$set(this, "item", item);
  }

  async saveChanges() {
    if (!await this.$refs.form.validate()) return;

    if (this.editing)
      return await this.update();
  }

  async update() {
    const res = await this.$api.maintenance.vincere.Update(this.item.id, {...this.editingItem});
    this.editing = false;
    this.$set(this, "item", res);
  }

  // Editing Functions
  get hasBeenEdited() {
    return !isEqual(this.item, this.editingItem)
  }

  editingItem?: MaintenanceAPI.VincereInstance.Item = null;

  @Watch('item', {immediate: true, deep: true})
  watchItem() {
    this.$set(this, "editingItem", JSON.parse(JSON.stringify({...this.item})));
  }


  async reauthoriseVincere() {
    const callback = location.origin + this.$router.resolve({
      name: "maintenance-vincerecallback"
    }).href;

    const url = `https://id.vincere.io/oauth2/authorize?response_type=code&client_id=${this.item.vincereClientId}&redirect_uri=${callback}&state=${this.item.id}`;

    Object.assign(document.createElement('a'), {
      target: '_blank',
      rel: 'noopener noreferrer',
      href: url,
    }).click();
  }
}
